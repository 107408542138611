import React, { useState } from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import logo from '../Assets/logo.svg';
import { SocialIcon } from 'react-social-icons';


function NavBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Wrapper>
      <div className='Nav'>
        <NavLink to={'/'}>
          <img src={logo} className='logo' />
        </NavLink>
        <div className='hamburger' onClick={toggleSidebar}>
          ☰
        </div>
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <div className='sidebar-links'>
            <NavLink to={'/about'} activeClassName='active' onClick={toggleSidebar}>
              <button className='page-button'>About</button>
            </NavLink>
            <NavLink to={'/blog'} activeClassName='active' onClick={toggleSidebar}>
              <button className='page-button'>Blog</button>
            </NavLink>
            <NavLink to={'/session'} activeClassName='active' onClick={toggleSidebar}>
              <button className='page-button'>Contact</button>
            </NavLink>
            <div className='social-icons'>
              <SocialIcon url='https://www.instagram.com/u.tuo.bang/' bgColor='#947f5f'/>
              <SocialIcon url='https://www.facebook.com/lan.w.ying' bgColor='#947f5f'/>
              <SocialIcon url='https://www.youtube.com/@shanghang0330' bgColor='#947f5f'/>
              <SocialIcon url='https://www.linkedin.com/in/chua-shang-hang/' bgColor='#947f5f'/>
            </div>
          </div>
        </div>
        <div className='pages'>
          <NavLink to={'/about'} activeClassName='active'>
            <button className='page-button'>About</button>
          </NavLink>
          <NavLink to={'/blog'} activeClassName='active'>
            <button className='page-button'>Blog</button>
          </NavLink>
          <NavLink to={'/session'} activeClassName='active'>
            <button className='page-button'>Contact</button>
          </NavLink>
        </div>
      </div>
    </Wrapper>
  );
}

export default NavBar;

const Wrapper = styled.div`
  .Nav {
    padding-top: 0px;
    background-color: rgba(217, 201, 154, 1);
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
  }

  .pages {
    gap: 10px;
    margin-bottom: 0px;
    margin-right: 60px;
  }

  .page-button {
    background-color: transparent;
    padding: 0px 30px;
    font-size: 2.0em;
    border: none;
    font-family: serif;
    color: black;
    font-weight: 500;
  }

  .logo {
    height: 115px;
    margin-left: 30px;
    margin-bottom: 3px;
  }

  .active .page-button {
    font-weight: bold;
    text-decoration: underline;
  }

  .hamburger {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    margin-right: 30px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: -250px; //move it out of view initially
    height: 100%;
    width: 250px;
    background-color: rgba(162, 151, 119, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: left 0.3s ease-in-out;
    z-index: 200;
  }

  .sidebar.open {
    left: 0; //move it into view
  }

  .sidebar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .social-icons {
    display: grid;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: -130px;
  }


  @media (max-width: 700px) {
    .pages {
      display: none;
    }

    .hamburger {
      display: block;
    }

  }
`;

