import React from 'react';
import styled from 'styled-components';

function Calendar() {
  return (
    <Wrapper>
        <div>
            <iframe
            src="https://embed.styledcalendar.com/#OXQNQxsAUGRfUIDmd9V9"
            title="Styled Calendar"
            data-cy="calendar-embed-iframe"
            className='fc'
            ></iframe>
        </div>
    </Wrapper>

  )
}

export default Calendar;

const Wrapper = styled.div`
text-align: center;
justify-content: center;

iframe {
  width: 100%;
  height: 700px; 
  border: none;
}

.fc {
  margin-top: 0px;
  padding: 70px;
}

@media (max-width: 700px) {
.fc {
  padding: 40px;
}

}


`;
