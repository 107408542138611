import React from 'react';
import styled from 'styled-components';
import NavBar from './NavBar';
import Blogcard1 from './Blogcards/Blogcard1';
import Blogcard2 from './Blogcards/Blogcard2';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';


function Blog() {
  return (
    <Wrapper>
      <NavBar />
      <Content>


        <div className='desctitle'>
        <h1>Some thoughts... 🤷🏻‍♂️</h1>
        <br/>
        <p className='desc'>
          Here's where I pen my thoughts and express my ideas.
          <br/>
          这些都是我写的一些文章，希望你会喜欢 :)
        </p>
      </div>
        <NavLink to={'/article1'}>
          <Blogcard1 />
        </NavLink>
        <NavLink to={'/article2'}>
          <Blogcard2 />
        </NavLink>
      <Footer />
      </Content>
    </Wrapper>
  )
}

export default Blog;

const Wrapper = styled.div`
.desctitle {
  padding: 90px;
  padding-bottom: 50px;
}

.desc {
  font-size: 1.3em;
  text-align: justify;
}

@media (max-width: 700px) {
.desctitle {
  padding-left: 40px;
  padding-right: 40px;
};

.desc {
  font-size: 1.1em;
}

}


}

`

const Content = styled.div`
  padding-top: 70px; /* Ensure content starts below the navbar */

`;