import Homepage from "./Components/Homepage";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import About from './Components/About';
import Blog from './Components/Blog';
import Session from './Components/Session';
import Article1 from "./Components/Articles/Article1";
import Article2 from "./Components/Articles/Article2";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/session",
    element: <Session />,
  },
  {
    path: "/article1",
    element: <Article1 />,
  },
  {
    path: "/article2",
    element: <Article2 />,
  }
]);


function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

