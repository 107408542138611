import React from 'react';
import styled from 'styled-components';
import NavBar from './NavBar';
import Calendar from './Calendar';
import CheckOutMyCalendar from '../Assets/CheckOutMyCalendar.gif';
import Footer from './Footer';

function Session() {
  return (
    <Wrapper>
      <NavBar />
      <Content>
        <article className='article-style'>
          <h1>
            Let's Talk! 🗣
          </h1>
          <br/>
          <p>
            Having navigated the SPM, the scholarship application cycle, and A-Levels myself, 
            I'm more than happy to offer some support in the following subjects:
            <li>SPM Mathematics</li>
            <li>SPM Additional Mathematics</li>
            <li>A-Level Mathematics</li>
            <li>A-Level Further Mathematics</li>
            <br/>
            You can also get in touch with me if you need some guidance on:
            <li>SPM preparation</li>
            <li>A-Levels preparation</li>
            <li>Scholarship application</li>
            <li>UK university applications</li>
            <br/>
            Feel free to check my calendar below for available time slots. You can also reach out to me on any of my social media platforms.
            <br/>
            <br/>
            <em>Just a heads-up: I'm not a professional tutor, just a fellow student who has experienced 
            all these and understands how stressful and challenging this journey can be. I've been 
            through it all, and I'm happy to share what I've learned.</em> 😉           
          </p>
          <p>
            I don’t charge anything for these sessions - just one student helping another. 
            But if you're feeling generous, a cup of coffee is always appreciated! ☕️
          </p>
          <img src={CheckOutMyCalendar} className='ccmc'/>
        </article>
        
      

      </Content>
      <Calendar />

      <Footer />

    </Wrapper>
  );
}

export default Session;

const Wrapper = styled.div`
.article-style {
  margin: 90px;
  text-align: justify;
  margin-bottom: 0px;
}

p {
  font-size: 1.3em;
}

.ccmc {
  margin-top: 30px;
}

@media (max-width: 700px) {
.article-style {
  margin-right: 40px;
  margin-left: 40px;
}

p {
    font-size: 1.1em;
  }
}

.ccmc {
  width: 250px;
  margin-left: 35px;
}

`;

const Content = styled.div`
  padding-top: 70px; /* Ensure content starts below the navbar */
  display: flex;

`;






