import React from 'react'
import styled from 'styled-components';
import bgvid from '../Assets/background-video1.mp4';
import NavBar from './NavBar';
import BuyMeCoffee from './BuyMeCoffee';
import Footer from './Footer';
import backgroundvid from '../Assets/homepage-bg-video.gif';

function Homepage() {
  return (
    <Wrapper>

      <NavBar />

      
      {/* <VideoBackground autoPlay loop muted>
          <source src={bgvid} type="video/mp4" />
      </VideoBackground> */}

      <GifBackground>
        <img src={backgroundvid}></img>

      </GifBackground>

      <BuyMeCoffee />
      
  
    </Wrapper>
  );
}

export default Homepage;

const Wrapper = styled.div`
    background-color: #efff9d4;

`;

const GifBackground = styled.div`
    height: 100vh;
    position: relative;

    &>img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
}

`;

const VideoBackground = styled.video`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: grayscale(-10%);
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px auto; /* Center the video horizontally */
  text-align: center; /* Center the video container's content */
`;

const Content = styled.div`
  color: black;
  text-align: center;
  font-size: 2em;
  padding: 20px;
`;
