import React from 'react';
import styled from 'styled-components';
import cshanghang from '../Assets/c-shanghang.svg';
import { SocialIcon } from 'react-social-icons';

function Footer() {
  return (
    <Wrapper>
    <div className='footer'>
        <img className='cshanghang' src={cshanghang}></img>
        <div className='socials'>
        
            <SocialIcon url='https://www.instagram.com/u.tuo.bang/' bgColor='black'/>
            <SocialIcon url='https://www.facebook.com/lan.w.ying' bgColor='black'/>
            <SocialIcon url='https://www.youtube.com/@shanghang0330' bgColor='black'/>
               
             <SocialIcon url='https://www.linkedin.com/in/chua-shang-hang/' bgColor='black'/>
             {/* <SocialIcon url='https://www.xiaohongshu.com/user/profile/
                            6472c2a00000000011001010?xhsshare=CopyLink&
                            appuid=6472c2a00000000011001010&apptime=172
                            2657894&share_id=effd64d7693045298471b1dc61
                            347705' bgColor='black'/>  */}

        </div>

        
      
    </div>

    <div>

    </div>
    </Wrapper>
  )
}

export default Footer;

const Wrapper = styled.div`
.footer {
    width: 100%;
    background-color: black;
    color: grey;
    padding: 20px;
    display: flex;
    margin-top: 30px;
}

.cshanghang {
    width: 200px;
}

.socials {
    display: flex;
    margin-top: 18px;
    margin-left: 13px;
}

@media (max-width: 700px) {
.footer {
    width: 100%;
    background-color: black;
    color: grey;
    padding: 5px;
    display: flex;
    margin-top: 10px;
}

.socials {
    width:30%;
    margin-top: 20px;
}

}
`

