import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Footer from '../Footer';



function Article1() {
  return (
    <Wrapper>
        <div>

        </div>

            
        <article className='article-style'>
          <NavLink to={'/blog'} style={{ textDecoration: 'none' }}>
            <p className='Back'>Back</p>
          </NavLink>
          <h1>
            The Beginning
          </h1>
          <Badges>
              <DateBadge>4 Aug 2024</DateBadge>
              <CategoryBadge>Leisure</CategoryBadge>
          </Badges>
          <p>
            I offer free tuitions/advices for subjects below. Having gone through SPM, scholarship
            application cycle and A-Levels myself, I am more than happy to offer some help:
            <li>SPM Mathematics</li>
            <li>SPM Additional Mathematics</li>
            <li>A-Level Mathematics</li>
            <li>A-Level Mathematics</li>
            If you need help with:
            <li>scholarship application</li>
            <li>UK university applications</li>

            Disclaimer: By all means I am not a professional! Just a student willing to help. (As I know how stressful it can be.)
          </p>
          <p>
            Below is my calendar. Check for my free time. Reach out to me on any of my social media!
            <br/> P.S: You can always buy me a cup of coffee! 😉😆
          </p>
        </article>
        
      <Footer />
    </Wrapper>
  )
}

export default Article1;

const Wrapper=styled.div`
.article-style {
  margin: 90px;
  text-align: justify;
  margin-top: 30px;
  margin-bottom: 0px;
};

.Back {
  background-color: transparent;
  font-size: 2.0em;
  color: #d9c99a;
  font-weight: 500;
  margin-bottom: 20px;
}

h1 {
  font-size: 3.5em;
  margin-bottom: 10px;    
};

p {
  font-size: 1.3em;
  margin-top: 10px;
};

@media (max-width: 700px) {
  .article-style {
    margin-left: 40px;
    margin-right: 40px;
  };

  h1 {
    font-size: 2.2em;
  };

  p {
    font-size: 1.1em;
  }


  
}

};
`

const Badges = styled.div`
  display: flex;
  gap: 10px;
  color: white;
`;

const Badge = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;

  .date {
  background-color: blue
  }

  category {
  background-color: red
  }

`;

const DateBadge = styled(Badge)`
  background-color: #cbb87c; /* Specific color for date */
`;

const CategoryBadge = styled(Badge)`
  background-color: #00286d; /* Specific color for category */
`;



