import React from 'react';
import styled, { keyframes } from 'styled-components';
import NavBar from './NavBar';
import myPhoto1 from '../Assets/myPhoto1.jpg';
import myPhoto2 from '../Assets/myPhoto2.jpg';
import Footer from './Footer';

function About() {
  return (
    <Wrapper>
      <NavBar />
      <Content>
      <article className='article-style'>
          <div>
            {/* <h1 className='typewriter'>
              Hello there! 👋 
            </h1> */}
            <h1>Hello there! 👋</h1>
            <br/>
          </div>
          <div className='div1'>
            <p>30-Second Bio:
              <ul>
                <li>🙋🏻‍♂️ Full name: Shang Hang Chua, but I love it when people call me Shanghang.</li>
                <li>🌾 Hometown: Sungai Petani, a small town in Kedah, Malaysia.</li>
                <li>⚡ YTN (Yayasan Tenaga Nasional) scholar.</li>
                <li>💻 Data Science undergraduate at the London School of Economics (LSE), graduating in 2025.</li>
                <li>📡 Interested in and working on technology.</li>
                <li>🎭 Possess a burning passion for the performing arts.</li>
                <li>✒️ Amateur writer in various forms, including articles and songs.</li>
              </ul>
              <br/>
                30秒自我介绍:
              <ul>
                <li>🙋🏻‍♂️ 全名为蔡尚航。可以叫我尚航 :)</li>
                <li>🌾 来自稻米之镇 —— 马来西亚吉打州的双溪大年</li>
                <li>⚡ 很幸运地获得了国能基金会的奖学金</li>
                <li>💻 伦敦政治经济学院 (LSE) 数据科学本科生，预计在2025年毕业</li>
                <li>📡 对科技很有兴趣也积极地参与其中</li>
                <li>🎭 热衷于表演艺术</li>
                <li>✒️ 不成熟的业余写手，喜欢写作、作词</li>
              </ul>
            </p>
            <img className='img1' src={myPhoto2} />
          </div>
          <div className='title2'>
            <h1>Why <em>utuobang?</em> 🎡</h1>
            <br/>
          </div>
          <div className='div2'>
            {/* <h1 className='typewriter'>
              Why '<em>utuobang</em>'? 🎡
            </h1> */}
          </div>

          <div className='div1'>
            <img className='img2' src={myPhoto1} />
            <p>
              '乌托邦' (wu tuo bang) means utopia. I created utuobang on Instagram back in 2020, uploaded my first post in July 2020.
              The purpose of utuobang is to provide a safe space to express my thoughts, in a hopefully, poetic, metaphorical and analogical way.

              I started building this website to create a more personal and controlled connection between me and my audience (if I'm fortunate enough to have any). 
              Because it's my website, I can do whatever I want!


            </p>

          </div>
          
        </article>
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default About;

// Define the keyframes
const typing = keyframes`
  0% { width: 0; }
  40% { width: 100%; }
  70% { width: 100%; } /* Hold the last frame (100% width) */
  90% { width: 0; }
`;

const blinkCaret = keyframes`
  from, to { border-color: transparent; }
  50% { border-color: orange; }
`;

const Wrapper = styled.div`
  .article-style {
    margin: 90px;
    text-align: justify;
    padding-bottom: 0px;
  }

  .typewriter {
    color: #000;
    font-family: monospace;
    font-size: 3em;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.15em solid orange; /* The typewriter cursor */    
    white-space: nowrap; /* Keeps the content on a single line */
    letter-spacing: 0.15em; /* Adjust as needed */
    width: 0; /* Initial width for typewriter effect */
    animation: ${typing} 10s steps(30, end) infinite 1s, ${blinkCaret} 0.5s step-end infinite;
  }

`;

const Content = styled.div`
  padding-top: 70px; /* Ensure content starts below the navbar */

  .div1 {
    display: flex;
    text-align: left;
  }

  .div2 {
    margin-top: 10px;
  }

  p {
    font-size: 1.3em;
  }

  .img1 {
    width: 40%;
    height: 40%;
    margin-left: 40px; 
  }

  .img2 {
    width: 30%;
    margin-right: 40px;
  }

  .title2 {
  margin-top: 70px;
  }

  ul {
    padding-left: 20px; /* Remove default padding */
  }

  li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

  @media (max-width: 700px) {
  .div1 {
    display: grid;
    }

  p {
    font-size: 1.1em;
  }

  .img1 {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .img2 {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
  }
  
  .article-style {
    margin-right: 40px;
    margin-left: 40px;
  }

`;




