import React from 'react';
import styled from 'styled-components';
import blog2 from './BlogcardPhoto/blog2.jpeg'; 

function Blogcard2() {
  return (
    <Wrapper>
      <Card>
        <ImageWrapper>
          <img src={blog2} alt="Article" />
        </ImageWrapper>
        <Overlay>
          <Header>
            <Title>YTN Scholarship</Title>
            <Badges>
              <DateBadge>4 Aug 2024</DateBadge>
              <CategoryBadge>Education</CategoryBadge>
            </Badges>
          </Header>
          <Excerpt>
            Tips on how to secure a YTN scholarship
          </Excerpt>
        </Overlay>
      </Card>
    </Wrapper>
  );
}

export default Blogcard2;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

@media (max-width: 700px) {
  padding: 0px;
  padding-bottom: 20px;

  }
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  margin-left: 60px;
  margin-right: 60px;

@media (max-width: 700px) {
  margin-left: 40px;
  margin-right: 40px;

  }
`;

const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: 150px;;
    object-fit: cover;
    object-position: center 10%;
  }

@media (max-width: 700px) {
  img {    
    object-position: center 34%;
  }
}

`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 27px;
  background: rgba(0, 0, 0, 0.3); /* Overlay with transparency */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

@media (max-width: 700px) {
  display: grid;
}

`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
  color: white;

@media (max-width: 700px) {
  font-size: 1.5em;
}

`;

const Badges = styled.div`
  display: flex;
  gap: 10px;
`;

const Badge = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;

  .date {
  background-color: blue
  }

  category {
  background-color: red
  }

@media (max-width: 700px) {
  margin-top: 10px;
}
`;

const Excerpt = styled.p`
  font-size: 1.1rem;
  margin: 0px 0 10px 0;
  max-width: 600px;

@media (max-width: 700px) {
  font-size: 0.9em;
  margin-top: 10px;

}
`;

const DateBadge = styled(Badge)`
  background-color: #cbb87c; /* Specific color for date */
`;

const CategoryBadge = styled(Badge)`
  background-color: #e2ae00; /* Specific color for category */
`;
