import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Footer from '../Footer';
import photo1 from './ArticlePhoto/article2-photo1.jpeg';
import photo2 from './ArticlePhoto/article2-photo2.jpg';



function Article2() {
  return (
    <Wrapper>  
        <article className='article-style'>
          <NavLink to={'/blog'} style={{ textDecoration: 'none' }}>
            <p className='Back'>Back</p>
          </NavLink>
          <h1>
            YTN Scholarship
          </h1>
          <Badges>
              <DateBadge>4 Aug 2024</DateBadge>
              <CategoryBadge>Education</CategoryBadge>
          </Badges>
          <div className='TLDRBlock'>
            <h2><em>TL;DR:</em></h2>
            <ul>
              <li>2 Types of Scholarships: Local and Overseas</li>
              <li>Courses Offered: Engineering, Data Science, Accounting</li>
              <li>3 Stages: Application, Self-Introductory Video, Interview</li>
              <li>Application:
              Submit all necessary documents.
              <br/>
              <em>Tips: Prepare and organize your documents beforehand. 
                Prioritize your most significant achievements.</em></li>
              <li>Self-Introductory Video:
              Submit a 3-minute video.
              <br/><em>Tips: Prepare a script, edit the video for smooth flow, and be as creative as possible.</em></li>
              <li>Interview:
              A 30-minute session with three interviewers, covering both behavioral and technical questions.
              <br/>
              <em>Tips: Research the company, "sophisticate" your achievements without lying, and be genuine. 
                Confidence is crucial! Share personal stories to make your responses more engaging.</em></li>
            </ul>

          </div>
          <div>
            <p>
              <em>
                Disclaimer: I wrote this article back in 2021. My application took place during COVID 
                so I believe many things have changed since then. 
                Please take my experience and suggestions with a pinch of salt. 🙂
              </em>
            </p>
            <br/>
            <p>
              <h2>Introduction</h2>
              As of 2020, Yayasan Tenaga Nasional (Tenaga Nasional Foundation) offers two types of scholarships: 
              Local and Overseas. For Local scholarships, the courses offered are Engineering 
              (Mechanical, Civil, and Electrical Power), Finance, Accounting, Computer Science, and 
              Data Science and Analytics. For Overseas scholarships, the courses available are 
              Electrical Power Engineering, Accountancy, and Data Science and Analytics. 
              To qualify for the Yayasan Tenaga Nasional Scholarship (local and international), 
              a student must obtain at least 8 A's in SPM. Being active in sports and co-curricular 
              activities and demonstrating excellent leadership skills are two other vital criteria 
              in selecting YTN scholars. For more details, please visit <a href="https://www.tnb.com.my/about-tnb/scholarships">this website</a>.
            </p>
            <br/>
            <p>
              <h2>Selection Process</h2>
              The selection process for candidates from my batch was quite different due to COVID-19. 
              It was moved entirely online since physical interviews were not allowed. 
              Despite unstable internet connections and occasional lagging video calls, 
              the process was enjoyable and exciting! The selection process was divided into three main stages:
            </p>
            <br/>
            <p>
              <h5>1. Application Stage</h5>
              You must submit all your documents, including personal information, SPM results, 
              and co-curricular achievements. In the co-curricular section, prioritize your achievements 
              and place them at the top of the list (if I'm correct, there is a limited number of certificates 
              you are allowed to submit). If there is a limit, ensure that the extracurricular achievements you 
              include are related to the course you're applying for. For instance, I applied for Data Science and 
              Analytics, so I listed my achievements in STEM competitions and innovation contests. Doing so will 
              give you a significant advantage, as it shows that you are genuinely passionate about your desired 
              course and have made an effort to gain more experience in that field.
              <br/>
              <br/>
              <em>My tips: Prepare and organize all your certificates before the application process begins. 
              The sooner, the better. You will want to submit the most important certificates that could 
              help you stand out throughout the selection process.</em>
            </p>
            <br/>
            <p>
              <h5>2. Self-Introductory Video</h5>
              All applicants who pass the application stage must record a 3-4 minute self-introductory video. 
              The video's main idea is to present yourself creatively and uniquely. You'll need to introduce yourself, 
              explain why you applied for this scholarship, etc. You only have about 3 minutes, so time management is 
              crucial. Remember to keep your speech concise and be as creative as possible!
              <br/>
              <br/>
              <em>My tips: Prepare a script. Avoid speaking spontaneously when recording the video, as you might come 
              across as unprepared and awkward. Also, edit the video to remove long pauses and make the flow smoother. 
              Focus on small details and do everything you can to perfect the video. Try your best to impress the interviewers.</em>
            </p>
            <br/>
            <p>
              <h5>3. Interview</h5>
              Here comes the ultimate challenge: the individual interview session. Typically, there will be three interviewers. 
              Two are usually YTN officers, and one is a professional from your desired course working for TNB. They'll take 
              turns asking you questions. Some questions are pre-set, while others are tailored to you. Since they've watched 
              your video, they will have a rough idea of your background. However, they won't know much about your personality. 
              Therefore, try to be as active and confident as possible! They'll start by asking you to introduce yourself, then 
              proceed with more technical and specific questions.

              My personal experience: Near the end of the interview, one of the interviewers asked me to do a room tour. 
              (YES, you read that right, a ROOM TOUR!) THANK GOODNESS I remembered to clean my room and make my bed before 
              the interview; otherwise, I might have embarrassed myself, and the scholarship could have possibly slipped away. 
              So, do remember to clean your rooms, kids. ;) The interview session typically lasts between 30 to 45 minutes. 
              It may seem quite long, but trust me, time fliesssss~ 
              <br/>
              <br/>
              <em>My tips: During your self-introduction, avoid mentioning trivial things such as your birthday or favorite food. 
              Instead, talk about your plans (e.g., dream university and career aspirations) and where you see yourself in the 
              next 5 or 10 years. Try to include information that reflects your passion for the course you're applying for. 
              Also, mention your family's financial situation and explain how they may struggle to afford your college and 
              university fees. Highlighting your need for financial aid can help validate and strengthen your 
              application for this scholarship.</em>
            </p>
            <br/>
            <figure className='figure-block'>
              <img src={photo1} className='photo'></img>
              <figcaption><em>YTN Scholarship Award Ceremony! 😝</em></figcaption>
            </figure>
            <br/>
            <p>
              <h2>Common interview questions</h2>
              <ol>
              <li>What are your strengths and weaknesses?</li>
              <li>What do you know about the course you're applying for?</li>
              <li>What do you know about our company?</li>
              <li>How would you contribute to our company?</li>
              <li>What makes you special?</li>
              </ol>
              More often than not, people tend to give bland and generic answers, which is the last thing you 
              want to do during your interviews. Remember, the purpose of the interview is for the interviewers to 
              get to know who YOU are beneath all your triumphs and achievements. Therefore, it's essential to be genuine!
            </p>
            <br/>
            <p>
              <h5>1. What are your strengths and weaknesses?</h5>
              You're highly encouraged to share personal stories when answering this question. For weaknesses, 
              demonstrate to the interviewers that you have made or are trying to make improvements.
            </p>
            <br/>
            <p>
              <h5>2. What do you know about the course you're applying for?</h5>
              The interviewers will only partially expect you to understand your desired course. 
              However, you SHOULD at least grasp the basic ideas and fundamentals of the course. 
              For example, "Data Science and Analytics combine Computer Science and Mathematics (particularly Statistics). 
              Most Data Scientists use Python or R as their programming tools". It would be beneficial if you 
              could elaborate on this! The more, the better! 
            </p>
            <br/>
            <p>
              <h5>3, 4. What do you know about our company? / How would you contribute to our company?</h5>
              Doing thorough background research on TNB and YTN is essential, as no company wants to invest 
              hundreds of thousands of ringgit in someone who knows little or nothing about them, right?
            </p>
            <p>
              Make an effort to know the following:
              <ul>
                <li>A bit of the company's history</li>
                <li>Their vision and mission</li>
                <li>What they wish to accomplish in the next 5 to 10 years</li>
                <li>Their ultimate goals</li>
              </ul>
              Then, relate your desired course to these aspects. For example, if you're applying for Data Science and 
              Analytics, you can explain how you'd ensure data is analyzed correctly and precisely and how you'd be able 
              to persuade potential investors to make future investments.
              <br/>
              <br/>
              <em>My tips: All major companies have their investors/collaborators. Make some effort to find out who those 
              investors/collaborators are. Cari sendiri ye ;)</em>
            </p>
            <br/>
            <p>
              <h5>5. What makes you so special?</h5>
              Again, relate your skills, strengths, and past experiences to YTN's vision, mission, goals, etc. 
              Emphasize how you would utilize this opportunity if you received it. Be creative with your answers. 
              I talked about my leadership and technical skills, my enthusiasm for the course, my experiences in invention 
              and innovation contests, and how I could be part of the team that would diversify and globalise TNB's 
              market in the future.
            </p>
            <br/>
            <p>
              <h5>Miscellaneous Questions (depending on the information you've submitted)</h5>
              <ul>
                <li>What characteristics should a good leader possess? / What is leadership to you?</li>
                <li>Tell me about your experience in participating in competitions or holding important positions.</li>
                <li>How do you manage your time?</li>
                <li>What was your SPM preparation process?</li>
              </ul>
            </p>
            <p>
              When answering these questions, don't just brag about your accomplishments; tell the interviewers 
              what lessons you've learned and what experiences you've gained from participating in those activities 
              and competitions. The interviewers want to see confidence and enthusiasm in the candidates and possibly 
              their future employees.
              <br/>
              <br/>
              <em>My tips: There are always a variety of answers to these questions. Amidst that, always stay 
              true to yourself. Try not to exaggerate too much to impress the interviewers, as they can 
              often tell if someone is being honest. However, know how to "package" yourself. It is not 
              wrong to "decorate and sophisticate" your accomplishments to a certain extent. ALWAYS show 
              your passion and enthusiasm. Convince them that you're a worthy investment.</em>
            </p>
            <br/>
            <figure className='figure-block'>
              <img src={photo2} className='photo'></img>
              <figcaption><em>Fellow YTN Overseas Scholars 👥</em></figcaption>
            </figure>
            <br/>
            <p>
              <h2>FAQ</h2>
              <h5>1. If my English speaking skills aren't strong, do I still have a chance of being offered 
              the scholarship?</h5>
              Yes, you do! As long as you can present and express yourself clearly and confidently, you 
              should be good to go. Share some of your personal stories if time allows; this will make your 
              answers much more interesting!
            </p>
            <br/>
            <p>
              <h5>2. What if I don't know how to answer a question in the interview?</h5>
              You can ask the interviewers for more time to answer it. Be frank if you still don't know 
              how to answer the question. It's better to admit that you don't know the answer rather than 
              try to fool the interviewers, right? 
            </p>
            <br/>
            <p>
              <h5>3. Would I be at a disadvantage if I'm not that outstanding in my co-curricular activities?</h5>
              You might be slightly disadvantaged if you have only a few co-curricular achievements. However, 
              regardless of the positions you've held or the activities you've participated in during secondary 
              school, if you can impress the interviewers by describing the lessons and experiences you've gained, 
              there's still a high possibility that you'll receive the scholarship!
            </p>
            <br/>
            <p>
              With all that said, try your best and have fun throughout the process! It's completely fine if you do not 
              manage to secure the scholarship; it is not the end of the world. There are still many ways to succeed in life, 
              RIGHT? Jangan berputus asa! ;D
              If you're a future SPM candidate, I wish you all the best in your SPM examination! And if you're a student 
              applying for scholarships and desperately searching for advice and tips, I hope this article helps. 
              Hopefully, you'll become <em>BETTER AND BRIGHTER!</em> (pun intended)
              <br/>
              <br/>
              <span className='bold-text'>GOOD LUCK!</span> 💪🤩
            </p>
          </div>


        </article>
        
      <Footer />
    </Wrapper>
  )
}

export default Article2;

const Wrapper=styled.div`
.article-style {
  margin: 90px;
  text-align: justify;
  margin-top: 30px;
  margin-bottom: 0px;
};

.TLDRBlock {
  Background-color: #f9f9f9; /* Light background color */
  border: 1px solid #ddd; /* Light border for definition */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Space inside the block */
  margin: 20px 0; /* Space outside the block */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  font-size: 1.2em;
  text-align: left;
}

.Back {
  background-color: transparent;
  font-size: 2.0em;
  color: #d9c99a;
  font-weight: 500;
  margin-bottom: 20px;
}

h1 {
  font-size: 3.5em;
  margin-bottom: 10px;    
};

p {
  font-size: 1.3em;
  margin-top: 10px;
};

.bold-text {
  font-weight: 600;
}

.figure-block { 
  text-align: center;
}

.photo {
  width: 100%
}


@media (max-width: 700px) {
  .article-style {
    margin-left: 40px;
    margin-right: 40px;
  };

  .TLDRBlock {
    font-size: 0.9em;
  }

  h1 {
    font-size: 2.2em;
  };

  p {
    font-size: 1.1em;
  }

};
`

const Badges = styled.div`
  display: flex;
  gap: 10px;
  color: white;
`;

const Badge = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;

  .date {
  background-color: blue
  }

  category {
  background-color: red
  }

`;

const DateBadge = styled(Badge)`
  background-color: #cbb87c; /* Specific color for date */
`;

const CategoryBadge = styled(Badge)`
  background-color: #e2ae00; /* Specific color for category */
`;